html {
  scroll-behavior: smooth;
}

.firstContainer {
  background-image: url('../../assets/background.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  line-height: 5rem;
  width: 100%;
  top: 0;
  position: fixed;
  transition: all 1s;
  z-index: 999;
}

.left span {
  font-size: 1.5rem;
  cursor: pointer;
  font-family: 'Lustria';
}

.checkBtn {
  display: none;
}

#check {
  display: none;
}

.checkBtn {
  cursor: pointer;
  font-size: 36px;
  float: right;
  color: '#A9B188';
  line-height: 80px;
}

.right ul {
  display: flex;
  list-style: none;
}

.right ul li a {
  padding: 10px 20px;
  font-size: 16px;
  color: inherit;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  transition: all 1s;
}

.right ul li a:hover {
  background-color: #edecea;
  border-radius: 8px;
  color: rgb(22, 7, 36);
}

.main-names {
  color: #333333;
  font-family: 'Retro';
  margin-bottom: 30px;
  font-size: min(144px, 12vw);
  /* font-weight: 700; */
}

.text-date {
  color: #333333;
  font-family: 'Lustria';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
}

.messageFormContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  padding-top: 40px;
}

@media screen and (max-width: 805px) {
  .list {
    width: 100%;
    height: 100vh;
    background-color: #edecea;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 4rem;
    left: 100%;
    transition: all 1s;
  }

  .left span {
    font-size: 1rem;
  }

  #check {
    display: none;
  }

  .checkBtn {
    display: block;
  }

  #check:checked ~ ul {
    left: 0%;
  }
}

.invisible {
  display: none;
}

@media (max-width: 600px) {
  .firstContainer {
    background-size: cover;
    width: 100%;
    justify-content: flex-start;
    background-position: top;
  }
  .main-names {
    margin-bottom: 0;
  }
  .text-date {
    font-size: 14px;
    margin-bottom: 40px;
  }

  .messageFormContainer {
    padding: 40px 20px;
  }
}
