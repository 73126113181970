.boxCardContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  color: #333333;
  font-size: 24px;
  font-weight: 400;
  font-family: 'Lustria';
  padding: 20px 15px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: none;
}

.subtitle {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Lustria';
  font-style: normal;
  letter-spacing: 2px;
  line-height: 1.75;
  color: rgb(51, 51, 51);
  text-transform: uppercase;
  max-width: 1110px;
  margin: 0px auto;
  padding: 20px 15px 50px;
  margin-bottom: 20px;
}

.description {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Raleway';
  padding: 60px 15px;
}

@media (max-width: 600px) {
  .subtitle,
  .description {
    text-align: center;
  }
}
